import { getLangFromUrl } from '../../../utils/getLangFromUrl';

export const start = {
    get links() {
        const { lang, code } = getLangFromUrl();
        return [
            { text: 'O mnie', path: `/${code}/${lang.pages.letter.path}` },
            { text: 'Otwórz CV', path: `/${code}/${lang.pages.cv.path}` },
        ];
    },
    path: 'poczatek',
    status: `Otwarta do zatrudnienia`,
    statusClosed: `Zatrudniona`,
};
